<template>
    <div class="box">
		<div>
			<h4 class="m-0">{{ $t('mouvement.residence_principale') }}</h4>

            <div class="row align-items-center" id="residence">
                <div v-if="residence" class="col mt-2">
                    <h5 class="m-0">{{ residence.lieu_label }}</h5>
                    <i v-if="(residence.lieu_adresse != null && residence.lieu_adresse != '') && (residence.lieu_cp != null && residence.lieu_cp != '') && (residence.lieu_ville != null && residence.lieu_ville != '')">{{ residence.lieu_adresse}}, {{ residence.lieu_cp }} {{ residence.lieu_ville | uppercase }}</i>
                </div>
                <div v-else-if="horseFicheReadOnly" class="col">
                    {{ $t("mouvement.renouveler_licence_choisir_lieu") }}
                </div>
                <div v-else class="col">
                    {{ $t("mouvement.choisir_lieu") }}
                </div>

                <div v-if="!horseFicheReadOnly" class="col-auto">
                    <div v-if="work_on_initial_mouv">
                        <font-awesome-icon :icon="['fal', 'spinner']" spin />
                    </div>
                    <div v-else>
                        <!-- Si on a déjà eu une entrée initiale, on propose directement le carnet d'adresse avec une modal pour la raison -->
						<span v-if="has_initial_mouv">
							<button 
								class="btn btn-link"  
								v-on:click.stop.prevent="changeResidence()"
							>
								<span class="d-md-inline">{{ $t('lieu.changer_lieu_residence') }}</span> <font-awesome-icon class="ml-2" :icon="['fal', 'long-arrow-right']" />
							</button>

							<button v-if="residence"
								class="btn btn-link"  
								v-on:click.stop.prevent="deleteHorseActualResidence()"
							>
								<span class="d-md-inline">{{ $t('lieu.delete_residence_principale') }}</span> <font-awesome-icon class="ml-2" :icon="['fal', 'long-arrow-right']" />
							</button>
						</span>
                        <!-- Si pas de mouvement d'entrée initiale enregistrée pour ce cheval, on redirige vers AjoutMouvement -->
                        <router-link v-else :to="{ name: 'mouvementAjout', params: { horse_id: horse_id, from: 'HorseFicheMouvements', first_mouv: true, type: 'entree' }}">
                            <span class="d-md-inline">{{ $t('lieu.definir_lieu_residence') }}</span> <font-awesome-icon class="ml-2" :icon="['fal', 'long-arrow-right']" />
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <ChangePlaceForm  ref="modal-change-place" :success_callback="saveModifResidence" />
		<GetOutPlaceForm  ref="modal-delete-place" :success_callback="addGetOutMouvement" />
    </div>
</template>



<script type="text/javascript">
    import { EventBus } from 'EventBus';
    import Mouvements from "@/mixins/Mouvements.js";
    import Lieux from "@/mixins/Lieux.js";
    import Horse from "@/mixins/Horse.js";	
	import Shutter from '@/mixins/Shutter.js'
    import ShutterMvt from '@/mixins/shutters-manager/Mouvements.js'

	export default {
		name: "HorseResidence",
		mixins: [Mouvements, Lieux, Horse, Shutter, ShutterMvt],
        props: [
            'horse_id',
        ],
        components: {
			ChangePlaceForm: () => import("@/components/Horse/ModalChangingPlace"),
			GetOutPlaceForm: () => import("@/components/Horse/ModalGetOutPlace")
        },
		data () {
			return {
                residence: null,
                compo_name: '',
                new_residence: null,
                has_initial_mouv: false,
                work_on_initial_mouv: true,
			}
		},
		mounted() {
            this.compo_name = this.$route.name

            // On init les données de la vue
            this.init_component()
		},
		methods: {
            init_component() {
                this.getResidence()
                this.getInitialMouvement()
            },

            async getResidence(reload=false) {
				const horse_residence = await this.getHorseMainResidence(this.horse_id, reload)

                if(horse_residence) {
                    this.residence = horse_residence.lieu
				}
				else if(reload) {
					this.residence = null
				}
            },

            async getInitialMouvement() {
                this.work_on_initial_mouv = true

                this.getInitialProvenance(this.horse_id).then(result => {
                    if(result && result.mouvement_id) {
                        this.has_initial_mouv = true
                    }
					else {
						// Sinon on cherche si le cheval a un premier mouvement quelconque
						this.getHorseLastResidence(this.horse_id).then(res => {
							if(res && res.length > 0) this.has_initial_mouv = true
						})
					}
                }).catch(e => {
                    // Si on arrive dans le catch, c'est que l'on a aucune entrée initiale
                }).finally(() => {
                    this.work_on_initial_mouv = false
                })
            },

            changeResidence() {
				this.openCarnetAdresse({ residences_only: true })
            },

            lieuChoosed(residence) {
                this.new_residence = residence
                this.openModal()
            },

            async saveModifResidence(data) {
				await this.updateHorseResidence(this.horse_id, this.new_residence.lieu_id)

                const result = {
                    'mouvement_date': data.date,
                    'mouvement_raison': data.raison,
                    'mouvement_label': this.new_residence.lieu_label,
                    'mouvement_adresse': this.new_residence.lieu_adresse,
                    'mouvement_cp': this.new_residence.lieu_cp,
                    'mouvement_ville': this.new_residence.lieu_ville,
                    'mouvement_type': this.new_residence.lieu_type,
                    'mouvement_horse': this.horse_id,
                    'mouvement_lieu': this.new_residence.lieu_id
                }
                await this.addMouvement(result)

                // On maj la résidence
                EventBus.$emit('HistoriqueMouvement::updateMouvements')
                EventBus.$emit('HorseFiche::refreshHorsePlace')
                this.getResidence(true)
			},

			async deleteHorseActualResidence() {
				// On récupére la structure actuelle du cheval, pour vérifier si elle est la même que la résidence principale
				const actual_place = await this.getHorseActualPlace(this.horse_id)

				// On supprime la résidence principale
				await this.deleteHorseResidence(this.horse_id)

				// S'il s'agit bien d'un mouvement vers une résidence
				if(actual_place && actual_place.mouvement_type === 1) {
					const actual_resid = actual_place.mouvement_lieu

					// Si la dernière entrée est sur la résidence principale, on propose un mouvement de sortie
					if(actual_resid == this.residence.lieu_id) {
                		this.$refs['modal-delete-place'].openModal()
					}
				}

				this.getResidence(true)
			},

			addGetOutMouvement() {
				this.$router.push({ name: 'mouvementAjout', params: { horse_id: this.horse_id, from: 'HorseFicheMouvements' }})
			},

            openModal() {
                this.$refs['modal-change-place'].openModal()
            }
		},
        computed: {
            horseFicheReadOnly() {
                return this.$store.state.horseFicheReadonly
            }
        }
	}
</script>
